<template>
  <div class="adSeries">
    <div class="adIterm">
      <p class="title">请选择推广业务:</p>
      <div class="pageList">
        <div
          v-for="(item, index) in businessList"
          :key="index"
          :class="['pageItem', ruleForm.business == item.key ? 'active' : '']"
          @click="choosePage(item, index)"
        >
          <div :class="['iconfont', pages[index].icon]"></div>
          <p>{{ item.key }}</p>
          <span class="tips">
            {{ pages[index].tips }}
          </span>
          <i class="iconfont icon-duihao"></i>
        </div>
      </div>
    </div>
    <div class="adIterm">
      <p class="title">请选择营销目标 :</p>
      <div class="pageList">
        <div
          :class="['pageItem', 'targetItem', index == targetIndex ? 'active' : '']"
          v-for="(item, index) in objectivesList"
          :key="index"
          @click="chooseTarget(item, index, false)"
        >
          <div :class="['iconfont', targets[pageIndex][index].icon]"></div>
          <p>{{ item.desc }}</p>
          <span class="tips">
            {{ targets[pageIndex][index].tips }}
          </span>
          <i class="iconfont icon-duihao"></i>
        </div>
      </div>
    </div>

    <campaign-info v-if="creatAdSet && ruleForm.business !== 'FACEBOOK_PAGE'"></campaign-info>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="adSeriesForm"
      label-width="120px"
      class="demo-ruleForm"
      v-if="ruleForm.business !== 'FACEBOOK_PAGE'"
    >
      <el-form-item
        label="智能购物广告:"
        prop="smart_promotion_type"
        v-if="ruleForm.objective == 'OUTCOME_SALES'"
      >
        <el-checkbox
          v-model="ruleForm.smart_promotion_type"
          true-label="AUTOMATED_SHOPPING_ADS"
          :false-label="null"
          >智能购物广告</el-checkbox
        >
      </el-form-item>
      <el-form-item
        label="推广网址:"
        prop="siteUrl"
      >
        <span slot="label">
          <span>推广网址</span>
          <web-tips></web-tips>：
        </span>
        <el-autocomplete
          v-model="ruleForm.siteUrl"
          :fetch-suggestions="querySearch"
          placeholder="请输入推广网址"
          @select="selectwebLink"
          :disabled="edit"
          style="width: 600px; margin-right: 10px"
          @change="onInputWebLink"
          @input="clearCheckStatus"
        >
          <div slot="prepend">
            <el-select
              v-model="httpType"
              placeholder="请选择"
            >
              <el-option
                key="http://"
                label="http://"
                value="http://"
              >
              </el-option>
              <el-option
                key="https://"
                label="https://"
                value="https://"
              >
              </el-option>
            </el-select>
          </div>
          <template slot-scope="item">
            <div>{{ item.item }}</div>
          </template>
        </el-autocomplete>

        <el-button
          type="text"
          icon="el-icon-aim"
          @click="openLink(ruleForm.siteUrl)"
          :disabled="!ruleForm.siteUrl"
          >预览</el-button
        >
        <p
          v-if="!edit"
          :class="['tipsColor', checkLinkStatus && checkLinkStatus.level ? checkLinkStatus.level : '']"
          v-loading="checkLoading"
        >
          {{ checkLinkStatus ? checkLinkStatus.message : '' }}
        </p>
      </el-form-item>
      <sale-form
        @saleInfo="saleInfo"
        v-if="!creatAdSet && ruleForm.business !== 'FACEBOOK_PAGE'"
        :msg="ruleForm"
        ref="addSale"
      ></sale-form>
      <!-- <el-form-item label="" prop="conversion_domain" style="width:800px">
                <span slot="label">
                    <span>网域：</span>
                </span>
                <el-input placeholder="网域仅包含一级域和二级域，如giikin.com" v-model="ruleForm.conversion_domain"></el-input>
            </el-form-item> -->
      <el-form-item
        label="转化事件："
        prop="customEventType"
      >
        <div class="seletItem">
          <el-select
            v-model="ruleForm.fbPixelId"
            placeholder="请选择像素"
            @change="selectPixelStatic"
            :disabled="edit"
            style="width: 90%"
          >
            <el-option
              :value="item.pixel_id"
              v-for="(item, index) in pixelLists"
              :key="index"
              :label="item.name"
            >
              <p>
                {{ item.name }}
                <span
                  v-if="item.tips"
                  style="color: #e6a23c; margin-left: 10px"
                  >【{{ item.tips }}】</span
                >
              </p>
            </el-option>
          </el-select>
          <el-tooltip
            class="item"
            effect="dark"
            content="强制刷新像素，清除本地缓存像素数据"
            placement="top"
          >
            <el-button
              icon="el-icon-refresh"
              @click="$emit('getPixels', true)"
              type="text"
              size="mini"
            ></el-button>
          </el-tooltip>
          <p style="color: #999">当前像素id：{{ ruleForm.fbPixelId || '--' }}</p>
        </div>
        <div class="seletItem">
          <el-select
            v-model="ruleForm.customEventType"
            placeholder=""
            :disabled="edit"
          >
            <el-option
              :value="item.event.customEventType"
              v-for="(item, index) in pixelEventTypeList"
              :key="index"
              :label="item.event.title"
            >
              <span :class="['point', item.count > 0 ? '' : 'active']"></span>
              <span>{{ item.event.title }}</span>
              <span
                style="margin-left: 10px"
                v-if="item.count"
                >已收到{{ item.count }}个活动</span
              >
            </el-option>
            <div
              slot="empty"
              class="empty"
              v-loading="loadingPix"
            >
              <span>
                <i class="iconfont icon-wushuju"></i>
                <p>暂无数据</p>
              </span>
            </div>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item
        label=""
        prop="conversion_domain"
        style="width: 800px"
      >
        <span slot="label">
          <span>网域：</span>
        </span>
        <!-- <el-input placeholder="网域仅包含一级域和二级域，如giikin.com" v-model="ruleForm.conversion_domain"></el-input> -->
        <web-domain
          :initWebDomain="ruleForm.conversion_domain"
          :webDomains="webDomains"
          @select-change="selectChange"
        ></web-domain>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import webDomain from './webDomain';
import mixWebDomain from '@/views/adManagement/assets/js/webDomain';
import {
  accountPixel,
  pixelStats,
  historyInput,
  websiteUrlCheck,
  domainsByFbpixels,
  promotionInfo,
} from '@/api/creatAd.js';
import webTips from './webTips';
import saleForm from './addSale';
import campaignInfo from './campaignInfo';
import pixelListVue from '../../../adAssets/components/pixelList.vue';
import { domainReg } from '@/utils/utils';
export default {
  props: ['accountId', 'edit', 'pixelList', 'creatAdSet', 'initData'],
  mixins: [mixWebDomain],
  data() {
    let validCustomSale = (rule, value, callback) => {
      if (!value && this.isCustomSale) {
        return callback(new Error('请输入推广网址'));
      }
    };
    return {
      pages: [
        { name: '网站', icon: 'icon-wangzhan', tips: '吸引更多用户访问或在网站上购买、订阅等有价值的操作。' },
        { name: '移动应用（APP）', icon: 'icon-shouji', tips: '推广移动应用，提升应用的下载、激活、付费等。' },
        { name: 'Facebook Page', icon: 'icon-fb', tips: '推广主页和帖子，提升账号的粉丝和互动量。' },
        { name: '消息互动', icon: 'icon-xiaoxi', tips: '通过消息互动，了解用户喜好、提升购买、订阅量等。' },
      ],
      targets: [
        [
          { name: '吸引更多网站访客', icon: 'icon-wangzhan', tips: '让更多目标用户访问您的网站。' },
          { name: '转化量', icon: 'icon-wangzhan', tips: '让更多用户在您的网站或应用执行更多有价值的操作。' },
          { name: '目录促销', icon: 'icon-wangzhan', tips: '根据目标受众动态展示目录中的商品。' },
          { name: '闪登广告', icon: 'icon-wangzhan', tips: '用网站中最受欢迎的商品快速生成广告创意和投放。' },
        ],
        [
          { name: '推广应用', icon: 'icon-wangzhan', tips: '让更多目标用户逐步到应用商店下载您的应用。' },
          { name: '转化量', icon: 'icon-wangzhan', tips: '让更多用户在您的网站或应用执行更多有价值的操作。' },
        ],
        [
          { name: '推广主页', icon: 'icon-wangzhan', tips: '让更多的用户点赞主页，成为主页粉丝。' },
          { name: '速推帖子', icon: 'icon-wangzhan', tips: '让更多用户浏览你的主页帖子，并与之互动。' },
        ],
        [
          { name: '吸引更多用户发消息', icon: 'icon-wangzhan', tips: '向用户展示可在Messenger与你互动的广告。' },
          { name: '转化量', icon: 'icon-wangzhan', tips: '让更多用户在您的网站或应用执行更多有价值的操作。' },
        ],
      ],
      pageIndex: 0,
      targetIndex: 0,
      ruleForm: {
        siteUrl: null, //推广网址
        customEventType: null, //转化事件，
        fbPixelId: null, //像素
        business: '网站', //推广业务，默认网站
        objective: 'OUTCOME_SALES', //推广目标，默认转化量,
        conversion_domain: null,
        smart_promotion_type: null,
      },
      rules: {
        siteUrl: [{ validator: validCustomSale, trigger: 'change' }],
        conversion_domain: [{ required: false, message: '请输入或选择正确的网域', trigger: 'change' }],
        customEventType: [{ required: true, message: '请选择转化事件', trigger: 'blur' }],
      },
      pixelStaticList: [],
      linkHistory: ['https://www.giikin.com/1', 'https://www.giikin.com/2'],
      checkLoading: false,
      checkLinkStatus: {
        message: '请输入完整的网址，需包含https或者http。例如：https://www.giikin.com/页面路径…',
        level: 'error',
      },
      source: null,
      loadingPix: false,
      httpType: 'https://',
      domainHistory: [],
      businessType: null,
      objective: null,
    };
  },
  components: {
    webTips,
    saleForm,
    campaignInfo,
    webDomain,
  },
  computed: {
    isCustomSale() {
      return this.ruleForm.smart_promotion_type !== 'AUTOMATED_SHOPPING_ADS';
    },
    businessList() {
      if (this.initData && this.initData.ad) {
        // this.choosePage(this.initData.ad.BusinessType[0],0)
        return this.initData.ad.BusinessType;
      } else {
        return [];
      }
    },
    objectivesList() {
      if (this.businessType) {
        return this.businessType.objectives;
      } else {
        if (this.initData && this.initData.ad) {
          if (this.ruleForm.business) {
            const obj = this.initData.ad.BusinessType.filter((item) => item.key == this.ruleForm.business);
            return obj[0].objectives;
          }
          return this.initData.ad.BusinessType[0].objectives;
        } else {
          return [];
        }
      }
    },
    pixelLists: {
      get() {
        return JSON.parse(JSON.stringify(this.pixelList)).filter((v) => !v.is_unavailable);
      },
      set(v) {},
    },
    pixelEventTypeList: {
      get() {
        let list = this.pixelStaticList;
        if (this.ruleForm.smart_promotion_type == 'AUTOMATED_SHOPPING_ADS') {
          list = this.pixelStaticList.filter((v) => v.event.customEventType == 'PURCHASE');
        }
        if (!this.ruleForm.customEventType && list.length && this.ruleForm.objective === 'OUTCOME_SALES') {
          this.ruleForm.customEventType = list[0].event.customEventType;
        }
        return list;
      },
      set(v) {},
    },
  },
  methods: {
    selectChange(val) {
      let notNewAdd = this.apiWebDomains.includes(val);
      if (!notNewAdd) {
        let reg = /([0-9a-zA-Z-_]+\.\w{2,10})($|\/|\?)/;
        let pass = reg.test(val);
        if (pass) {
          let el = val.match(reg)[1];
          this.iptWebDomains.push(el);
          this.iptWebDomains = this.sortUniq(this.iptWebDomains);
          this.ruleForm.conversion_domain = val;
        } else {
          this.ruleForm.conversion_domain = null;
        }
      } else {
        this.ruleForm.conversion_domain = val;
      }
    },
    // 选择推广业务
    choosePage(item, index) {
      // 投fackbook主页
      this.businessType = { ...item };
      this.pageIndex = index;
      this.ruleForm.business = item.key;
      this.chooseTarget(this.businessType.objectives[0], 0, true);
      // this.targetIndex = -1;
    },
    // 选择营销目标
    chooseTarget(item, index, flag) {
      if (!flag && this.ruleForm.business == '网站') {
        if (index !== 0 && index !== 1) {
          return false;
        }
      }
      this.objective = item.key;
      this.targetIndex = index;
      this.ruleForm.objective = item.key;
      this.ruleForm.smart_promotion_type = null;
      if (item.key !== 'OUTCOME_SALES') {
        this.$set(this.ruleForm, 'customEventType', null);
      } else if (!this.ruleForm.customEventType) {
        this.$set(this.ruleForm, 'customEventType', this.pixelEventTypeList[0].event.customEventType);
      }
    },
    // 选择像素事件
    selectPixelStatic(v) {
      this.pixelStaticList = [];
      this.loadingPix = true;
      pixelStats({ pixelId: v }).then((res) => {
        // console.log(res);
        this.loadingPix = false;
        this.pixelStaticList = res.data.stats;
      });
    },
    // 输入网址校验
    selectwebLink(v) {
      // console.log(v)
      this.ruleForm.siteUrl = v;
      if (v) {
        this.onInputWebLink(v);
      }
    },
    trim(str) {
      return str.replace(/(^\s*)|(\s*$)/g, '');
    },
    // 校验网址是否存在前缀，若不存在就加上
    checkLink(v) {
      v = this.trim(v);
      if (v.indexOf('https://') >= 0) {
        this.httpType = 'https://';
        return v.slice(8);
      } else if (v.indexOf('http://') >= 0) {
        this.httpType = 'http://';
        return v.slice(7);
      } else {
        this.httpType = 'https://';
        return v;
      }
    },
    //
    clearCheckStatus() {
      this.checkLinkStatus = {
        message: '请输入完整的网址，需包含https或者http。例如：https://www.giikin.com/页面路径…',
        level: 'error',
      };
    },

    onInputWebLink(v) {
      if (!v) {
        this.checkLinkStatus = {
          message: '',
          level: 'error',
        };
        return false;
      }
      this.checkLoading = true;
      this.ruleForm.siteUrl = this.checkLink(v);
      let url = this.httpType + this.checkLink(v);
      websiteUrlCheck({ accountId: this.accountId, siteUrl: url }).then((res) => {
        // console.log(res);
        this.checkLoading = false;
        this.checkLinkStatus = res.data;
        if (res.data.level == 'ok') {
          res.data.message = '网址可用';
          let el = v.match(domainReg)[0];
          // this.$set(this.ruleForm,'conversion_domain',el)
          this.urlWebDomains = el;
          this.getInfoByLink(url);
        } else {
          this.urlWebDomains = '';
        }
      }).catch((err) => {
        this.urlWebDomains = '';
        this.checkLoading = false;
        this.clearCheckStatus();
      });
    },
    // 通过链接获取绑定的像素，商品和站点
    getInfoByLink(url) {
      promotionInfo({ url: url }).then((res) => {
        if (res.code == 0) {
          const data = res.data;
          // 标记像素
          this.pixelLists = this.pixelLists.map((v) => {
            if (data.adPixels && data.adPixels.length) {
              v.tips = data.adPixels.includes(v.pixel_id) ? '' : '未绑定站点';
            }
            return v;
          });
          if (data.site) {
            this.$refs['addSale'].remoteMethod(data.site?.id);
            this.$set(this.ruleForm, 'site', data.site);
          }
          if (data.sale) {
            this.$set(this.ruleForm, 'sale', data.sale);
          }
          // if (data.countryAutofill) {
            this.$set(this.ruleForm, 'countryAutofill', data.countryAutofill);
          // }
          this.$forceUpdate();
        }else{
          this.checkLinkStatus = {
            level:'error',
            message:res.comment
          }
          this.$set(this.ruleForm, 'site', null);
          this.$set(this.ruleForm, 'sale', null);
        }
      });
    },
    // 点击检查网址
    openLink(link) {
      // console.log(link);
      window.open(this.httpType + link);
    },
    // 获取账户像素，网址历史记录
    async getInit() {
      // this.$showLoading();
      // await accountPixel({accountId:this.accountId}).then(res=>{
      //     // this.hideLoading();
      // console.log(res);
      //     this.pixelList = res.data;
      //     this.ruleForm.fbPixelId =  this.campainInfo?this.campainInfo.pixel_id:res.data[0].pixel_id;
      //     this.selectPixelStatic(res.data[0].pixel_id)
      // });
      if (this.$route.query.promotion_link) {
        this.ruleForm.siteUrl = this.checkLink(decodeURIComponent(this.$route.query.promotion_link));
        this.onInputWebLink(this.ruleForm.siteUrl);
      }
      // this.checkLink(this.$route.query.promotion_link)
      await historyInput({ accountId: this.accountId, type: 'WEBSITE' }).then((res) => {
        this.linkHistory = res.data;
      });
      await historyInput({ accountId: this.accountId, type: 'CONVERSION_DOMAIN' }).then((res) => {
        this.domainHistory = res.data;
      });
    },
    querySearch(queryString, cb) {
      var restaurants = this.linkHistory;
      var results = queryString
        ? restaurants.filter((n) => {
            return n.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
          })
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    saleInfo(v) {
      this.ruleForm = { ...this.ruleForm, ...v };
    },
  },
  watch: {
    campainInfo: {
      handler() {
        this.ruleForm.siteUrl = this.checkLink(this.campainInfo.website_url);
        this.ruleForm.fbPixelId = this.campainInfo.pixel_id;
        this.selectPixelStatic(this.campainInfo.pixel_id);
        this.ruleForm.customEventType = this.campainInfo.pixel_event;
      },
      deep: true,
    },
    ruleForm: {
      handler() {
        let ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
        ruleForm.siteUrl = this.httpType + this.ruleForm.siteUrl;
        this.$emit('adSeriesMsg', ruleForm);
      },
      deep: true,
    },
    httpType() {
      let ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
      ruleForm.siteUrl = this.httpType + this.ruleForm.siteUrl;
      this.$emit('adSeriesMsg', ruleForm);
    },
    pixelList: {
      handler(val) {
        this.$nextTick((res) => {
          val = val.filter((k) => !k.is_unavailable);
          this.ruleForm.fbPixelId = this.campainInfo
            ? this.campainInfo.pixel_id
            : val.length && val[0].pixel_id
            ? val[0].pixel_id
            : '';
          if (val[0] && val[0].pixel_id) {
            this.selectPixelStatic(val[0].pixel_id);
          } //避免pixel_id undefined报错
        });
      },
    },
    'ruleForm.fbPixelId': {
      handler(val) {
        let curWebDomain = this.ruleForm.conversion_domain;
        let isUrl = [this.urlWebDomains].includes(curWebDomain);
        let isIpt = this.iptWebDomains.includes(curWebDomain);
        if (!isUrl && !isIpt) {
          this.ruleForm.conversion_domain = '';
        }
        if (val) {
          this.domainsByFbpixels(val);
        }
      },
    },
    'ruleForm.siteUrl': {
      handler(val) {
        if (!val) {
          this.urlWebDomains = '';
          this.ruleForm.conversion_domain = '';
        }
      },
    },
  },
  mounted() {
    this.getInit();
  },
};
</script>
<style scoped>
@import '//at.alicdn.com/t/font_2194132_71ft4zxpg2s.css';
</style>
<style lang="scss">
.point {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 10px;
  background: rgb(114, 192, 64);
  &.active {
    background: rgb(255, 77, 79);
  }
}
.empty {
  text-align: center;
  padding: 50px;
  font-size: 12px;
  color: #999;
  i {
    font-size: 36px;
    margin-bottom: 20px;
  }
}
.tipsColor {
  font-size: 12px;
  color: #666;
  &.error {
    color: #f56c6c;
  }
  &.warn {
    color: #e6a23c;
  }
  &.ok {
    color: #67c23a;
  }
}
.adSeries {
  .el-input-group__prepend {
    width: 100px !important;
  }
}
</style>
<style scoped lang="scss">
.adSeries {
  padding: 20px;
  background: #fff;
  min-width: 1200px;
  overflow: auto;
  .seletItem {
    display: inline-block;
    width: 400px;
    margin-right: 10px;
    vertical-align: top;
    .el-select {
      width: 100%;
    }
  }
  .adIterm {
    margin-bottom: 20px;
    .title {
      font-size: 16px;
      color: #333;
      font-weight: bold;
      margin: 20px 0;
    }

    .pageList {
      margin: 20px 0;
      display: flex;
      align-items: stretch;
      .pageItem {
        width: 260px;
        margin: 15px;
        padding: 20px;
        border: 1px solid #dddddd;
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        &.targetItem {
          width: 240px;
        }
        i.icon-duihao {
          position: absolute;
          top: 10px;
          right: 10px;
          &::before {
            font-size: 20px;
            color: transparent;
          }
        }
        &.active {
          border-color: #247ae6;
          i.icon-duihao {
            &::before {
              color: #247ae6 !important;
            }
          }
        }
        &:hover {
          transition: 0.5s all;
          transform: scale(1.05);
          i.icon-duihao {
            &::before {
              color: #ddd;
            }
          }
        }
        .iconfont {
          margin-bottom: 10px;
        }
        .iconfont::before {
          font-size: 36px;
          color: #247ae6;
        }
        p {
          font-size: 18px;
          color: #555;
          font-weight: bold;
          margin: 15px 0;
        }
        .tips {
          font-size: 14px;
          color: #999;
          line-height: 1.5;
        }
      }
    }
  }
}
</style>
