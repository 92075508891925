<template>
    <div class="addSale">
        <el-form v-model="saleInfo" :label-width="size?`${size}px`:'120px'" :size="size?'small':''" v-if="alwaysShowColl">
             <el-form-item label="商品ID"  prop="sale_id" v-if="saleRelationType === 'assign_sale'">
                 <el-input clearable v-model="saleInfo.sale_id" placeholder="请输入商品ID并搜索" @keyup.enter.native="getSaleInfo" @change="saleChange" :style="size && isNaN(size)?'width:100%':'width:70%'"></el-input>
                <div v-loading="saleLoading">
                    <div class="goodsMsg" v-if="saleMsg">
                        <div class="goodsImg">
                            <el-image :src="saleMsg.imageUrl"></el-image>
                        </div>
                        <div class="msg">
                            <p class="name">{{saleMsg.productName}}({{saleMsg.title}})</p>
                            <div><span>商品ID：{{saleMsg.saleId}}</span><span>产品ID：{{saleMsg.productId}}</span></div>
                        </div>
                        <p>
                            <span style="color:#E6A23C">{{saleMsg.uploader == userInfo.id ?'你不是该商品的上传人':''}}</span>
                        </p>
                    </div>
                    <div v-else class="goodsMsg">
                        <span style="color:#E6A23C">{{text}}</span>
                    </div>
                    <p  v-if="saleMsg">
                        <span style="color:#E6A23C">{{saleMsg.uploader != userInfo.id ?'你不是该商品的上传人':''}}</span>
                    </p>
                </div>
            </el-form-item>
            <el-form-item label="站点ID" prop="coll_id">
                 <el-select v-model="saleInfo.coll" filterable :remote="true" reserve-keyword placeholder="请输入站点ID" :remote-method="remoteMethod" @change="selectColl" value-key="id" :style="size && isNaN(size)?'width:100%':'width:70%'" clearable>
                    <el-option v-for="item in options" :key="item.id + item.orgCode" :label="item.domain+'#'+item.id+'('+item.saleName+')'" :value="item" >
                        <span>{{item.domain}}#{{item.id}}({{item.saleName}})</span>
                    </el-option>
                    <div slot="empty" class="empty" v-loading="loading">
                        <i class="iconfont icon-wushuju"></i>
                        <p>暂无数据</p>
                    </div>
                </el-select>
                
            </el-form-item>
        </el-form>
        <el-form v-model="saleInfo"  :label-width="size?`${size}px`:'120px'" :size="size?'small':''" v-else>
             <el-form-item label="商品ID" prop="sale_id">
                 <el-input clearable v-model="saleInfo.sale_id" placeholder="请输入商品ID并搜索" @keyup.enter.native="getSaleInfo" @change="saleChange" :style="size?'width:100%':'width:70%'" ></el-input>
                <div v-loading="saleLoading">
                    <div class="goodsMsg" v-if="saleMsg">
                        <div class="goodsImg">
                            <el-image :src="saleMsg.imageUrl"></el-image>
                        </div>
                        <div class="msg">
                            <p class="name">{{saleMsg.productName}}({{saleMsg.title}})</p>
                            <div><span>商品ID：{{saleMsg.saleId}}</span><span>产品ID：{{saleMsg.productId}}</span></div>
                        </div> 
                    </div>
                    <div v-else class="goodsMsg">
                        <span style="color:#E6A23C">{{text}}</span>
                    </div>
                    <p  v-if="saleMsg">
                        <span style="color:#E6A23C">{{saleMsg.uploader != userInfo.id ?'你不是该商品的上传人':''}}</span>
                    </p>
                </div>
            </el-form-item>
            <el-form-item label="站点ID" prop="coll_id">
                <el-select v-model="saleInfo.coll" filterable :remote="true" reserve-keyword placeholder="请输入站点ID" :remote-method="remoteMethod" @change="selectColl" value-key="id" :style="size&& isNaN(size)?'width:100%':'width:70%'">
                    <el-option v-for="item in options" :key="item.id + item.orgCode" :label="item.domain+'#'+item.id+'('+item.saleName+')'" :value="item" >
                        <span>{{item.domain}}#{{item.id}}({{item.saleName}})</span>
                    </el-option>
                    <div slot="empty" class="empty" v-loading="loading">
                        <i class="iconfont icon-wushuju"></i>
                        <p>暂无数据</p>
                    </div>
                </el-select>
                
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    import {promoteableSale,searchSites} from '@/api/creatAd.js'
    export default {
        props:['size','querySale','msg', 'alwaysShowColl', 'saleRelationType'],
        computed: {
            // 站点是否一直显示
        },
        data() {
            return {
                saleInfo:{
                    sale_id:null,
                    coll:null
                },
                loading:false,
                saleMsg:null,
                options:[],
                text:'请回车确认',
                rules:{
                    sale_id:[{ required: false, message: '请输入商品ID', trigger: 'blur' }],
                    coll_id:[{ required: true, message: '请输入站点ID', trigger: 'change' }],
                },
                saleLoading:false,
                userInfo:localStorage.getItem('userInfo')?JSON.parse(localStorage.getItem('userInfo')):{},
                errorGoods:false,
            }
        },
        mounted() {
            if(this.$route.query.goods_id){
                this.saleInfo.sale_id = this.$route.query.goods_id;
                console.log(this.saleInfo)
                this.getSaleInfo()
                this.$store.commit('num/SET_CURRENT_ADD_SALE_ID', this.saleInfo.sale_id)
            }
            // this.saleInfo.coll = this.$route.query.site_id;
            this.remoteMethod(this.$route.query.site_id || '');
        },
        methods: {
            saleChange(e){
                if(isNaN(e)){
                    this.saleMsg = null;
                    this.text = '请输入正确的商品ID'
                }
                if(!e){
                    this.saleMsg = null;
                    this.text = '请输入商品ID回车搜索'
                }
                this.getSaleInfo()
            },
            selectColl(){
                this.$emit('saleInfo',{sale:this.saleMsg,site:this.saleInfo.coll}, true)
            },
            testNum(num) { 
        　　　　if (num=="") { 
        　　　　　　this.text = '请输入商品ID回车搜索'
        　　　　　　return false; 
        　　　　} 
        　　　　if (!(/(^[1-9]\d*$)/.test(num))) { 
        　　　　　　this.text = '商品ID必须为正整数'
        　　　　　　return false; 
        　　　　}else { 
        　　　　　　return true;  
        　　　　} 
        　　},
            getSaleInfo(){
                this.saleInfo.sale_id = this.saleInfo.sale_id.toString().trim();
                if(this.saleInfo.sale_id>2147483647 || !this.testNum(this.saleInfo.sale_id)){
                    this.saleLoading = false;
                    this.$message({
                        type:'warning',
                        message:'请输入正确的商品ID'
                    })
                    this.saleMsg = null;
                    return false;
                }
                this.saleLoading = true;
                promoteableSale({sale_id:this.saleInfo.sale_id}).then(res=>{
                    this.saleLoading = false;
                    // console.log(res);
                    if(res.code == 0){
                        this.errorGoods = false;
                        if(!res.data.valid){
                            this.$message({
                                type:'warning',
                                message:res.data.error
                            })
                            this.saleMsg = null;
                            this.errorGoods = true;
                            this.text = res.data.error;
                            return false;
                        }
                        this.saleMsg = res.data.sale
                    }else{
                        this.saleMsg = null;
                        this.text = res.comment;
                        this.errorGoods = true;
                    }
                    
                }).catch(err=>{
                    this.saleMsg = null;
                    this.saleLoading = false;
                });
            },
            remoteMethod(query) {
                this.options = [];
                this.loading = true;
                console.log(query);
                searchSites({keyword:query || ''}).then(res=>{
                    // console.log(res);
                    this.loading = false;
                    if(res.code == 0 && res.data.length){
                        this.options = res.data;
                        if(this.$route.query.site_id && res.data.length == 1){
                            this.saleInfo.coll = res.data[0]
                        }
                    }else{
                        this.options = [];
                    }
                })
            }
        },
        watch:{
            msg:{
                handler(){
                    this.saleMsg = this.msg?.sale || null
                    this.saleInfo.coll = this.msg?.site || null
                    if (this.saleInfo.coll) {
                        // 判断集合中是否有当前站点信息  如果有则不 push
                        let flag = false
                        this.options.forEach(item => {
                            if (item.id === this.saleInfo.coll.id) {
                                flag = true
                            }
                        })
                        if (!flag) this.options.push(this.saleInfo.coll)
                    }
                    this.saleInfo.sale_id = this.msg?.sale?.saleId
                    this.$store.commit('num/SET_CURRENT_ADD_SALE_ID', this.saleInfo.sale_id)
                },
                deep:true,
                immediate:true
            },
            saleMsg:{
                handler(val){
                    this.$emit('saleInfo',{sale:this.saleMsg,site:this.saleInfo.coll})
                    this.$store.commit('num/SET_AUDIENCES_NEED_DATA', val)
                },
                deep:true
            },
            querySale:{
                handler(){

                },
                deep:true
            },
            saleRelationType(){
                if(this.saleRelationType != 'assign_sale' && this.alwaysShowColl){
                    this.saleInfo.coll = null;
                    this.saleInfo.sale_id = null;
                    this.saleMsg = null;
                }
            }
        },

    }
</script>
<style lang="scss" scoped>
    .goodsMsg{
        display: flex;
        align-items: center;
        padding: 10px;
        .goodsImg{
            width: 80px;
            height: 80px;
            flex:80px 0 0;
            .el-image{
                width: 100%;
                height: 100%;
            }
        }
        .msg{
            flex: 1;
            overflow: hidden;
            padding-left: 10px;
            color: #666;
            font-size: 14px;
            span{
                margin-right: 10px;
            }
        }
    }
</style>