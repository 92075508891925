import { render, staticRenderFns } from "./adSeries.vue?vue&type=template&id=384c2efd&scoped=true"
import script from "./adSeries.vue?vue&type=script&lang=js"
export * from "./adSeries.vue?vue&type=script&lang=js"
import style0 from "./adSeries.vue?vue&type=style&index=0&id=384c2efd&prod&scoped=true&lang=css"
import style1 from "./adSeries.vue?vue&type=style&index=1&id=384c2efd&prod&lang=scss"
import style2 from "./adSeries.vue?vue&type=style&index=2&id=384c2efd&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "384c2efd",
  null
  
)

export default component.exports